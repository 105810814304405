<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content} || AllianceBlock` : `AllianceBlock` }}</template>
    </metainfo>
    <MainHeader />
    <div class="max-w-7xl w-full mx-auto min-h-screen px-2">
        <router-view />
    </div>
    <OnRamperWidget v-if="this.$route.name !== 'knowMore'" />
    <MainFooter :useOverlap="['build', 'index', 'terminal'].indexOf(this.$route.name) !== -1 ? true : false" />
</template>

<script>

// https://github.com/nuxt/vue-meta/issues/699

import MainFooter from './components/MainFooter.vue';
import MainHeader from './components/MainHeader.vue';
import OnRamperWidget from './components/OnRamperWidget.vue';
import { useMeta } from 'vue-meta';

export default {
    name: 'App',
    components: {
        MainFooter,
        MainHeader,
        OnRamperWidget,
    },
    mounted() {
    window.fwSettings={
    'widget_id':80000002244
    };
    !function(){if("function"!=
typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
        document.getElementsByTagName('html')[0].classList.add('scroll-smooth');
    },
    setup() {
        useMeta({
            title: '',
            titleTemplate: '%s || AllianceBlock',
            description: 'DeFi Terminal makes it easy to set up and launch branded campaigns using no code, within an hour and with full ownership of your smart contracts.',
            // image: 'https://allianceblock.io/defiterm-meta.png',
            htmlAttrs: {
                lang: 'en'
            },
            meta: [
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:title',
                    content: 'Nexera DeFi Terminal · Liquidity mining and staking campaigns, your way',
                },
                {
                    property: 'og:description',
                    content: 'DeFi Terminal makes it easy to set up and launch branded campaigns using no code, within an hour and with full ownership of your smart contracts.',
                },
                {
                    property: 'og:url',
                    content: 'https://defiterm.io',
                },
                {
                    property: 'og:image',
                    content: 'https://allianceblock.io/defiterm-meta.png',
                },
                {
                    property: 'twitter:card',
                    content: 'summary_large_image',
                },
                {
                    property: 'twitter:title',
                    content: 'Nexera DeFi Terminal · Liquidity mining and staking campaigns, your way',
                },
                {
                    property: 'twitter:description',
                    content: 'DeFi Terminal makes it easy to set up and launch branded campaigns using no code, within an hour and with full ownership of your smart contracts.',
                },
                {
                    property: 'twitter:image',
                    content: 'https://allianceblock.io/defiterm-meta.png',
                },
            ]
        });
    },
}

// h1: Providing the best liquidity mining solution for the crypto space

</script>

<style>



</style>
