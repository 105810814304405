<template>

    <div class="w-full relative bg-gray-800 -mt-48 pt-36 -z-1" v-if="useOverlap"></div>
    <footer class="w-full relative bg-gray-800 py-8 px-2">
        <div class="max-w-7xl mx-auto">
            <div class="flex flex-col space-y-4 md:flex-row md:items-start md:space-y-0 justify-between">
                <div>
                    <img class="w-full max-w-[180px] mb-6" src="./../assets/logo-white.png" alt="AllianceBlock" />
                    <p class="max-w-[500px] text-white font-bold mb-2">Nexera seamlessly brings DeFi and TradFi together.</p>
                    <p class="max-w-[500px] text-white">The future of finance is an integrated system in which the best of both worlds can work together to increase capital flows and technological innovation — we’re making that a reality.</p>
                </div>
                <div class="">
                    <p class="w-full mb-8 text-decent">The DeFi Terminal is a product of Nexera</p>
                    <div class="w-full flex justify-start items-center mb-2">
                        <div class="w-6 opacity-70">
                            <img class="h-3" src="./../assets/location-marker.png" alt="Location" />
                        </div>
                        <p class="text-white/80">Radonweg 2D, 3542 AN Utrecht, The Netherlands</p>
                    </div>
                    <div class="w-full flex justify-start items-center mb-6">
                        <div class="w-6">
                            <img class="h-3" src="./../assets/dutch-flag.webp" alt="Netherlands" />
                        </div>
                        <p class="text-white/80">+31 6 10 17 80 95</p>
                    </div>
                    <div class="w-full flex items-center space-x-2 lg:space-x-4">
                        <!--<a href="https://allianceblock.io">
                            <img class="h-4 transition opacity-90 hover:opacity-100" src="./../assets/social-icons/website.svg" alt="Homepage" title="Homepage" />
                        </a>
                        <a href="https://t.me/NexeraFoundation">
                            <img class="h-4 transition opacity-90 hover:opacity-100" src="./../assets/social-icons/telegram.svg" alt="Telegram" title="Telegram" />
                        </a>
                        <a href="https://x.com/Nexera_Official">
                            <img class="h-4 transition opacity-90 hover:opacity-100" src="./../assets/social-icons/twitter.svg" alt="Twitter" title="Twitter" />
                        </a>
                        <a href="https://www.linkedin.com/company/NexeraFoundation">
                            <img class="h-4 transition opacity-90 hover:opacity-100" src="./../assets/social-icons/linkedin.svg" alt="LinkedIn" title="LinkedIn" />
                        </a>
                        <a href="https://discord.com/invite/fB4tkF52H5">
                            <img class="h-4 transition opacity-90 hover:opacity-100" src="./../assets/social-icons/discord.svg" alt="Discord" title="Discord" />
                        </a>
                        <a href="https://www.reddit.com/r/NexeraFoundation/">
                            <img class="h-4 transition opacity-90 hover:opacity-100" src="./../assets/social-icons/reddit.svg" alt="Reddit" title="Reddit" />
                        </a>-->
                        <!--<a href="https://www.instagram.com/allianceblock_official/">
                            <img class="h-4 transition opacity-90 hover:opacity-100" src="./../assets/social-icons/instagram.svg" alt="Instagram" title="Instagram" />
                        </a>
                        <a href="https://blog.allianceblock.io/">
                            <img class="h-4 transition opacity-90 hover:opacity-100" src="./../assets/social-icons/medium.svg" alt="Medium" title="Medium" />
                        </a>
                        <a href="https://www.youtube.com/c/AllianceBlock">
                            <img class="h-4 transition opacity-90 hover:opacity-100" src="./../assets/social-icons/youtube.svg" alt="YouTube" title="YouTube" />
                        </a>-->
                    </div>
                </div>
            </div>
            <p class="w-full mt-8 text-gray-500">&copy;2023 AllianceBlock | <a href="https://allianceblock.io/privacy" class="cursor-pointer hover:underline">Privacy Policy</a></p>
        </div>
    </footer>

</template>

<script>

export default {
    name: 'MainFooter',
    components: {

    },
    props: ['useOverlap']
}

</script>

<style scoped>



</style>
