<template>
    <img class="absolute h-auto w-full top-0 right-0 -z-1 object-cover max-w-[1200px]" src="./../assets/blurred-background.png" />
    <KnowMoreOpener />
    <DataMetrics />
    <ClientsList :showTextContent="false" :hideHeadline="true" />
    <CTABanner2 />
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MMKVQW2"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
</template>

<script>

import ClientsList from '@/components/ClientsList.vue';
import CTABanner2 from '../components/CTABanner2.vue';
import DataMetrics from '@/components/DataMetrics.vue';
import { event } from 'vue-gtag';
import KnowMoreOpener from '../components/KnowMoreOpener.vue';
import { useMeta } from 'vue-meta';

export default {
    name: 'PageKnowMore',
    components: {
        ClientsList,
        CTABanner2,
        DataMetrics,
        KnowMoreOpener,
    },
    mounted() {
        /*const freshworksContainer = document.getElementById('freshworks-container');

        if (freshworksContainer) {
            freshworksContainer.style.display = 'none !important';
        }*/

        if (process.env.VUE_APP_PATH && process.env.VUE_APP_PATH == 'https://defiterm.io') {
            event('page_view', {
                page_title: 'defiterm.io',
                page_location: 'index',
                page_path: 'https://defiterm.io/know-more',
                send_to: 'GTM-MMKVQW2'
            });
        }

        const script = document.createElement('script');
        script.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(), event: 'gtm.js'}); var f = d.getElementsByTagName(s)[0],j=d.createElement(s), dl=l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src ='https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);}) (window, document, 'script', 'dataLayer', 'GTM-MMKVQW2');";
    
        document.head.appendChild(script);
    },
    setup() {
        useMeta({
            title: 'Nexera DeFi Terminal · Liquidity mining and staking campaigns, your way',
            description: 'DeFi Terminal makes it easy to set up and launch branded campaigns using no code, within an hour and with full ownership of your smart contracts.',
        });
    },
}

</script>

<style>

/*#freshworks-container, #launcher-frame {
    display: none !important;
}*/

</style>
