<template>
    <img class="absolute h-auto w-full top-0 right-0 -z-1 object-cover max-w-[1200px]" src="./../assets/blurred-background.png" />
    <MainOpener />
    <DataTable :elementsPerPage="10" :showDisclaimer="true" />
    <CTABanner />
    <DataMetrics />
    <ChainsAndDexes />
    <CTAPanels />
    <ClientsList :showTextContent="false" />
    <VerifiedSecurity />
    <TutorialsList campaign="engage" />
    <CTABanner />
</template>

<script>

import ChainsAndDexes from '../components/ChainsAndDexes.vue';
import ClientsList from '../components/ClientsList.vue';
import CTABanner from '../components/CTABanner.vue';
import CTAPanels from '../components/CTAPanels.vue';
import DataMetrics from '../components/DataMetrics.vue';
import DataTable from '../components/DataTable.vue';
import { event } from 'vue-gtag';
import MainOpener from '../components/MainOpener.vue';
import TutorialsList from '../components/TutorialsList.vue';
import { useMeta } from 'vue-meta';
import VerifiedSecurity from '../components/VerifiedSecurity.vue';

export default {
    name: 'PageIndex',
    components: {
        ChainsAndDexes,
        ClientsList,
        CTABanner,
        CTAPanels,
        DataMetrics,
        DataTable,
        MainOpener,
        TutorialsList,
        VerifiedSecurity,
    },
    mounted() {
        if (process.env.VUE_APP_PATH && process.env.VUE_APP_PATH == 'https://defiterm.io') {
            event('page_view', {
                page_title: 'defiterm.io',
                page_location: 'index',
                page_path: 'https://defiterm.io',
                send_to: 'G-DHZJ64L3XS'
            });
        }
    },
    setup() {
        useMeta({
            title: 'Nexera DeFi Terminal · Liquidity mining and staking campaigns, your way',
            description: 'DeFi Terminal makes it easy to set up and launch branded campaigns using no code, within an hour and with full ownership of your smart contracts.',
        });
    },
}

</script>

<style scoped>

</style>
